<template>
    <div class="row">
        <div class="col-6">
            <card type="tasks" class="stacked-form">
                <div class="col-12">
                    <div class="row">
                        <base-input>
                            <label>Tipo de Operação:</label>
                            <el-select v-model="usage.Type" placeholder="Selecione o tipo de operação"
                                :disabled="disabledField">
                                <el-option v-for="item in typeOptions" :key="item.Key" :label="item.Label"
                                    :value="item.Key">
                                </el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <div class="row">
                        <base-input>
                            <label>ID da Utilização no SAP:</label>
                            <el-input v-model="usage.SapUsage" placeholder="Informe o ID da utilização no SAP" />
                        </base-input>
                    </div>
                    <div class="row">
                        <base-input>
                            <label>Lista de preço:</label>
                            <el-tooltip
                                content="A lista de preço informada será utilizada para buscar o preço unitário do item no pedido."
                                effect="light" :open-delay="100" placement="top">
                                <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                            </el-tooltip>
                            <el-input v-model="usage.PriceList" placeholder="Informe o nº da lista de preço" />
                        </base-input>
                    </div>
                    <div class="row">
                        <base-input>
                            <label>Distribuir Despesas:</label><br />
                            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                             v-model="usage.DistributeExpenses" :sync="true"  placeholder="Distribuir as despesas"/>
                        </base-input>
                    </div>
                </div>
            </card>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { EventBus } from "@/eventBus";
import constants from '@/util/constants';

export default {
    computed: {
        ...mapState('usage', ['usageCurrent']),
        ...mapState(['ecommerce']),
        ...mapState(['realm']),
    },
    data() {
        return {
            usage: {
                EcommerceName: "",
                Type: null,
                SapUsage: null,
                PriceList: null,
                DistributeExpenses:true

            },
            typeOptions: [
            ],
            disabledField: false
        }
    },

    methods: {
        save() {
            this.$emit("save");
        },
        validate() {
            if (this.$isNullOrEmpty(this.usage.Type)) {
                this.$showError("Tipo de Operação obrigatório.");
                return false;
            } else if (this.$isNullOrEmpty(this.usage.SapUsage)) {
                this.$showError("ID da Utilização no SAP obrigatório.");
                return false;
            } else
                return true;
        },
        getValue() {
            return this.usage;
        },
        load() {
            this.usage = this.usageCurrent;
            if (this.usageCurrent) {
                this.disabledField = true;
            }
        },
        loadTypeOptions() {
            this.typeOptions = constants.usageTypeOptions;

            if (this.realm != 'proxys')
                this.typeOptions = this.typeOptions.filter(item => item.Key != 11 && item.Key != 12);
        }
    },
    mounted() {
        EventBus.$on("loadUsage", () => this.load());
        this.loadTypeOptions();
        this.typeOptions.sort((a, b) => {
            if (a.Label < b.Label) {
                return -1;
            }
            if (a.Label > b.Label) {
                return 1;
            }
            return 0;
        });
    },
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
    border-color: #00ADB7 !important;
    background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
    color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
    color: #00ADB7;
    font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed !important;
}
</style>
